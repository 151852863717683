import Swiper from './swiper-bundle.min.js';

window.onload = function() {

    /* ***** ***** ***** ***** *****
    ** FEATURED ARTISTS 注目の作家
    ***** ***** ***** ***** ***** */

    const featuredSwiperItems = new Swiper('#featured_artists .swiper-container',
    {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 32,
        scrollContainer: true,
        grabCursor: true,
        mousewheel:{
            invert: false,
        },
        freeMode: true,
    });

    /* ***** ***** ***** ***** *****
    ** HOT THEME 注目のカテゴリー
    ***** ***** ***** ***** ***** */
    const featuredCategorySwiperItems = new Swiper('#featured_category .swiper-container',
    {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 6,
        scrollContainer: true,
        grabCursor: true,
        mousewheel:{
            invert: false,
        },
        freeMode: true,
        breakpoints: {
            840: { //840以上の時
              spaceBetween: 16,
            },
        },
    });

    /* ***** ***** ***** ***** *****
    ** NEW ARTWORKS 新着作品
    ***** ***** ***** ***** ***** */
    const newArtworksSwiperItems = new Swiper('#new_artworks .swiper-container ',
    {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 8,
        scrollContainer: true,
        grabCursor: true,
        mousewheel:{
            invert: false,
        },
        freeMode: true,
        breakpoints: {
            840: { //840以上の時
              spaceBetween: 24,
            },
        },
    });

    /* ***** ***** ***** ***** *****
    ** NEW ART PRODUCTS 新着アートプロダクト
    ***** ***** ***** ***** ***** */
    const newArtProductsSwiperItems = new Swiper('#new_art_products .swiper-container ',
    {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 8,
        scrollContainer: true,
        grabCursor: true,
        mousewheel:{
            invert: false,
        },
        freeMode: true,
        breakpoints: {
            840: { //840以上の時
              spaceBetween: 24,
            },
        },
    });

    /* ***** ***** ***** ***** *****
    ** SPECIAL 記事一覧
    ***** ***** ***** ***** ***** */
    const specialSwiperItems = new Swiper('#special .swiper-container ',
    {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 24,
        scrollContainer: true,
        grabCursor: true,
        mousewheel:{
            invert: false,
        },
        freeMode: true,
    });

    /* ***** ***** ***** ***** *****
    ** NEW ART GOODS 新着アートグッズ
    ***** ***** ***** ***** ***** */
    const newArtGoodsSwiperItems = new Swiper('#new_art_goods .swiper-container ',
    {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 8,
        scrollContainer: true,
        grabCursor: true,
        mousewheel:{
            invert: false,
        },
        freeMode: true,
        breakpoints: {
            840: { //840以上の時
              spaceBetween: 24,
            },
        },
    });

    /* ***** ***** ***** ***** *****
    ** NEW ART BOOKS 新着アートブック
    ***** ***** ***** ***** ***** */
    const newArtBooksSwiperItems = new Swiper('#new_art_books .swiper-container ',
    {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 8,
        scrollContainer: true,
        grabCursor: true,
        mousewheel:{
            invert: false,
        },
        freeMode: true,
        breakpoints: {
            840: { //840以上の時
              spaceBetween: 24,
            },
        },
    });

}
